// This is called a "splat route" and as it's in the root `/app/routes/`
// directory, it's a catchall. If no other routes match, this one will and we
// can know that the user is hitting a URL that doesn't exist. By throwing a
// 404 from the loader, we can force the error boundary to render which will
// ensure the user gets the right status code and we can display a nicer error
// message for them than the Remix and/or browser default.

import { Link } from 'react-router'
import { GeneralErrorBoundary } from '#app/components/error-boundary.tsx'
import { Button } from '#app/components/ui/button.tsx'
import { Icon } from '#app/components/ui/icon.tsx'

export function loader() {
	throw new Response('Not found', { status: 404 })
}

export function action() {
	throw new Response('Not found', { status: 404 })
}

export default function NotFound() {
	// due to the loader, this component will never be rendered, but we'll return
	// the error boundary just in case.
	return <ErrorBoundary />
}

export function ErrorBoundary() {
	return (
		<GeneralErrorBoundary
			statusHandlers={{
				404: () => (
					<ErrorPage
						status={404}
						title="Page not found"
						description="The page you are looking for doesn't exist or has been moved."
						redirect
					/>
				),
			}}
		/>
	)
}

interface IErrorPage {
	status: number
	title: string
	description: string
	redirect?: boolean
}

function ErrorPage(props: IErrorPage) {
	const { status, title, description, redirect = true } = props
	return (
		<div className="flex min-h-screen flex-col justify-between py-16">
			<div className="font-mono opacity-80">{status}</div>
			<div className="flex flex-col gap-y-6">
				<h1 className="text-5xl font-semibold tracking-wide">{title}</h1>
				<p className="text-lg tracking-wide opacity-80">{description}</p>
				{redirect && (
					<Link to="/">
						<Button className="mt-3 w-fit font-normal tracking-wide">
							<Icon name="arrow-left">Return home</Icon>
						</Button>
					</Link>
				)}
			</div>
			<div />
			{/* 
      <pre className="whitespace-pre-wrap break-all text-body-lg">
        {location.pathname}
      </pre> */}
		</div>
	)
}
